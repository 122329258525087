import logo from './logo.svg';
import './App.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';

function App() {
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState('');

    const [activeData, setActiveData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [backlogData, setBacklogData] = useState([]);

    useEffect(() => {
        axios.get('/data/categories.json').then(response => {
            const { categories } = response.data;

            setCategories(categories);

            if (window.location.hash) {
                setActiveCategory(window.location.hash.replace('#', ''));
            }
            else {
                setActiveCategory(categories[0].id);
                window.location.hash = categories[0].id;
            }
        });
    }, []);

    useEffect(() => {
        if (activeCategory) {
            setIsLoading(true);
            axios.get(`/data/${activeCategory}.category.json`).then(response => {
                const { current, backlog } = response.data;

                setActiveData(current || null);
                setBacklogData(backlog || []);

                setTimeout(() => setIsLoading(false), 1000);
            });

            window.location.hash = activeCategory;
        }
    }, [activeCategory]);

    return (
        <div className="App">
            <div id="main">
                <header>
                    <h1>spotlight</h1>
                </header>
                <div id='categories'>
                    {categories.map(category =>
                        <div
                            className={['category', activeCategory === category.id && 'active'].join(' ')}
                            onClick={() => setActiveCategory(category.id)}
                        >
                            {category.label}
                        </div>
                    )}
                </div>
                <div id='spotlight' style={{ display: isLoading ? 'none': 'block' }}>
                    <div id='image-container'>
                        <div id='image' style={{ backgroundImage: `url(${activeData.image_url || ''})` }} />
                        <div id='image-bg' style={{ backgroundImage: `url(${activeData.image_url || ''})` }}></div>
                    </div>
                    <h1>{activeData.title}</h1>
                    <h2>{activeData.subtitle}</h2>
                </div>
                <div id='backlog' style={{ display: isLoading ? 'none': 'block' }}>
                    {/* <h3>⭐️ Previous</h3> */}
                    {backlogData?.length === 0
                        ? <p>Currently there are no previous items for this category.</p>
                        : backlogData.map(item =>
                            <div className='backlog-item'>
                                <div className='img' style={{ backgroundImage: `url(${item.image_url})` }}></div>
                                <div className='info'>
                                    <p className='title'>{item.title}</p>
                                    <p className='subtitle'>{item.subtitle}</p>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div id='loader' style={{ display: isLoading ? 'block' : 'none' }}>
                    <img src='loader.gif' />
                </div>
            </div>
        </div>
    );
}

export default App;
